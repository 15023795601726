import { Image, Carousel, Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from 'react';

function Roadster() {
const data = {"name":"Elon Musk's Tesla Roadster","launch_date_utc":"2018-02-06T20:45:00.000Z","launch_date_unix":1517949900,"launch_mass_kg":1350,"launch_mass_lbs":2976,"norad_id":43205,"epoch_jd":2459205.316793981,"orbit_type":"heliocentric","apoapsis_au":1.664041426415428,"periapsis_au":0.9857448763809588,"semi_major_axis_au":319.7258571238231,"eccentricity":0.2559816047500304,"inclination":1.07588256753148,"longitude":316.9315419980089,"periapsis_arg":177.681588077764,"period_days":557.0186156476041,"speed_kph":109278.66672000001,"speed_mph":67902.59441847312,"earth_distance_km":57928338.62299857,"earth_distance_mi":35994989.69851125,"mars_distance_km":66007564.59111287,"mars_distance_mi":41015186.417544395,"flickr_images":["https://farm5.staticflickr.com/4615/40143096241_11128929df_b.jpg","https://farm5.staticflickr.com/4702/40110298232_91b32d0cc0_b.jpg","https://farm5.staticflickr.com/4676/40110297852_5e794b3258_b.jpg","https://farm5.staticflickr.com/4745/40110304192_6e3e9a7a1b_b.jpg"],"wikipedia":"https://en.wikipedia.org/wiki/Elon_Musk%27s_Tesla_Roadster","video":"https://youtu.be/wbSwFU6tY1c","details":"Elon Musk's Tesla Roadster is an electric sports car that served as the dummy payload for the February 2018 Falcon Heavy test flight and is now an artificial satellite of the Sun. Starman, a mannequin dressed in a spacesuit, occupies the driver's seat. The car and rocket are products of Tesla and SpaceX. This 2008-model Roadster was previously used by Musk for commuting, and is the only consumer car sent into space."}

   function paintCarouselItems(){
    return
      <Carousel.Item>
        <Image
          src={"/img/1.jpg"}
          alt="First slide" width = "1024" height = "768" />
        <Carousel.Caption>
          <h3>{data.name}</h3>
          <p>{data.details}</p>
        </Carousel.Caption>
      </Carousel.Item>
    }

    
  
   function printImage(i){
    let url = "/img/" + i + ".jpg";
    return <img src = {url}  />;

   }
   function printItems(){
    let imgs = [1,2,3,4];
    return (
      <Carousel interval = "1000">
        <Carousel.Item>

         <img src = {"/img/1.jpg"}  />

          </Carousel.Item>
        
          <Carousel.Item>
        <img src = {"/img/2.jpg"}  />
          </Carousel.Item>
          
        <Carousel.Item>
        <img src = {"/img/3.jpg"}  />
          </Carousel.Item>
          
        <Carousel.Item>
        <img src = {"/img/4.jpg"}  />
        <Carousel.Caption>

          </Carousel.Caption>
          </Carousel.Item>
      </Carousel>
    )
   }
    return (
      <div className="Roadster">
          <Container>
        <Row>
        <Col md = {1}>  </Col>
        <Col md = {9}>
        {printItems()}

        </Col>
        </Row>
        </Container>
      </div>
    );
  }
  
  export default Roadster;
  