import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Badge, Button, Modal,Table} from 'react-bootstrap';
function Launches() {
  const route = {version:'v3', endpoint:'launches' } ;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [video, setVideo] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    let routeString = route.version + '/' + route.endpoint;
    fetch('https://api.spacexdata.com/' + routeString)
    .then(response => response.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  function changeVideo(launch){
    launch.newUrl = 'https://www.youtube.com/embed/' + launch.links.youtube_id;
    setVideo({name: launch.mission_name, date: launch.launch_date_utc, url: launch.newUrl});
    handleShow();
  } 
  function renderYoutube(launch){
    if(launch.links.video_link){
      return <Button variant="success" size = "sm" onClick={() => changeVideo(launch)} >Video</Button>
    }
  }
  function arrayElements(item){
    if(item){return (<Badge pill bg="primary">
    {item.length}
  </Badge>)
}
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  
  } else {
    return (
      <div>
      <Container>
        <Row>
          <Col md = {1}>  </Col>
          <Col md = {9} >
       
          {
            isLoaded ?        <Table table-sm striped bordered hover variant="dark">

            <thead>
                <tr>
            <th>Launch Name</th>
              <th>Date</th>
              <th>Rocket</th>
              <th>Engine</th>
              <th>Payloads</th>
              <th>Video</th>
              </tr>
            </thead>
            <tbody>
              {data.map(launch => (
                <tr key={launch.flight_id}>
                  <td>{launch.mission_name}</td>
                  <td>{(new Date(launch.launch_date_utc)).toLocaleDateString("en-US")}</td>
    
                  <td>{launch.rocket.rocket_name}</td>
                  <td>{launch.rocket.rocket_type}</td>
    
                  <td>{arrayElements(launch.rocket.second_stage.payloads)}</td>
    
                  <td>{renderYoutube(launch)}</td>
    
                </tr>
              ))}
            </tbody>
            </Table> : <h3>Loading...</h3>
          }
    
        </Col>
        </Row>
        </Container>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{video.name} | {(new Date(video.date)).toLocaleDateString("en-US")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p></p>

        <iframe 
    width='100%' 
    height='300px' 
    src={video.url + '?autoplay=1'}
    title='YouTube video player' 
    frameborder='0'
    
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' 
    allowfullscreen>
</iframe>

          </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
      
        </Modal.Footer>
      </Modal>

        </div>
    );
  }
}

export default Launches