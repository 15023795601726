import { Nav, Navbar, NavbarBrand, Container , NavDropdown} from 'react-bootstrap';

function NavApp({tabs, switchTab}) {
  function printItem(item){
    return (<Nav.Link href={"#" + item} onClick = {() =>switchTab(item)}>{item}</Nav.Link>);
  }
  function printModules(modules){
    return (modules.map((item) => {return printItem(item)}))
    }
  

  return (
    <Container fluid>
    <Navbar bg="dark" className='navbar-dark' expand="lg" fixed = "top">
      <Container>
        <Navbar.Brand>SpaceXHub</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {printModules(tabs)}
          </Nav>
        </Navbar.Collapse>

      </Container>
      <Navbar.Collapse className="justify-content-end">
          <Navbar.Brand variant = "light">
          David Ayele 2/4/2024
          </Navbar.Brand>
        </Navbar.Collapse>

    </Navbar>
    </Container>
  );
}

export default NavApp;
