import React, { useState, useEffect } from 'react';
import {Row, Col, Button, Container, Modal, Tab, Table } from 'react-bootstrap';
function Missions() {
  const route = {version:'v3', endpoint:'missions' } ;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  const [mission, setMission] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    let routeString = route.version + '/' + route.endpoint;
    fetch('https://api.spacexdata.com/' + routeString)
    .then(response => response.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  function showDetails(mission){ 
    setMission(mission);
    setShow(true);
  }
  function renderDetails(mission){
      return <Button variant= "success" size = "sm" onClick={() => showDetails(mission)} >Details</Button>
    
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div>
      <Container>
        <Row>
        <Col md = {1}>  </Col>
        <Col md = {9}>
{isLoaded ? 
      <Table table-sm striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Mission</th>
            <th>Manufacturer</th>
            <th>Payloads</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map(mission => (
            <tr key={mission.mission_id}>
              
              <td>{mission.mission_name}</td>
              <td>
                {mission.manufacturers.map(manufacturer => (
                    <li key={manufacturer}>{manufacturer}</li>
                    ))}
              </td>
              <td>
               { mission.payload_ids.map(payload => (
                    <li key={payload}>{payload}</li>
                    ))}
              </td>
              <td>
                {renderDetails(mission)}
              </td>
            </tr>
          ))}
        </tbody>
        </Table>
        :  <h3>Loading...</h3>
               }
        </Col>
        </Row> 
        </Container>
          <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>         {mission.mission_name}
</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {mission.description}

  
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
        
          </Modal.Footer>
        </Modal>
        </div>
    );
  }
}

export default Missions