import logo from './logo.svg';
import './App.css';
import Launches from './Modules/Launches';
import Rockets from './Modules/Rockets';
import Roadster from './Modules/Roadster';
import Missions from './Modules/Missions';
import NavApp from './NavApp';
import React, { Fragment } from 'react';
import { Row,Container, Navbar, NavItem } from 'react-bootstrap';
class App extends React.Component{
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      activeTab: ''
    }
  }


  handleClick = (newTab) => {
    this.setState({activeTab: newTab});
  
  }

 content(){
  switch(this.state.activeTab){
    case 'Rockets':
return <Rockets />
    case 'Launches':
      return <Launches />
    case 'Roadster':
      return <Roadster />
      case 'Missions' :
        return <Missions/>
    default:
      return <Roadster/>
  }
 }
render(){
  return (
    <Container fluid>
      <NavApp tabs = {this.props.modules} switchTab = {this.handleClick} />
<Navbar>
  <NavItem>&nbsp;</NavItem>
  </Navbar>
  <br />
      <Container min-height="110vh">
        <Row>
     {this.content()}
     </Row>
     </Container>
    </Container>
  )
  
}}

export default App;
